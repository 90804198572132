import React, {useState} from "react";
import {SelectServerStyled} from "./selectserver.styled";

const SelectServer = (props) => {

  let jsonObject = props.availableNodes.map(JSON.stringify);
  let uniqueSet = new Set(jsonObject);
  let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

  return (
  <SelectServerStyled>
    <div className={'containerino'}>
      <div className={'selection-table'}>
        <div className={'selection-head-row'}>
            <div className={'head-cell'}>ID</div>
            <div className={'head-cell'}>Name</div>
        </div>
        <div className={'selection-body-section'}>
          {uniqueArray.map(element => {
            return (
              <div key={element[0]} className={`selection-body-row ${props.selectedServers.find(elementor => elementor === element[0]) === element[0] ? 'selected' : ''}`} onClick={() => {
                if (element[2] !== true) {
                  return 0;
                }
                if (props.selectedServers.find(elementor => elementor === element[0]) !== element[0]) {
                  if (props.single === 1) {
                    props.setSelectedServer([element[0]]);
                  } else {
                    props.setSelectedServer([...props.selectedServers, element[0]]);
                  }
                } else {
                  const filterOut = (param) => {
                    return param !== element[0];
                  }
                  props.setSelectedServer(props.selectedServers.filter(filterOut))
                }
              }
              }>
                <div className={`body-cell ${element[2] !== true ? 'disallowed' : ''}`}><p>{element[0]}</p></div>
                <div className={`body-cell ${element[2] !== true ? 'disallowed' : ''}`}><p>{element[1]}{` ${element[2] !== true ? '[NO EXEC]': ''}`}</p></div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  </SelectServerStyled>
  )
}

export default SelectServer;