import styled from 'styled-components';

export const ExecuteViewStyled = styled.div`
    color: white;
    text-align: center;
    height: 100%;
    width: 100%;

    header {
        background-color: ${props => props.theme.colors.red};
        font-family: ${props => props.theme.fonts.technical};
        width: 100%;
        min-height: 50px;
        display: block;
    }

    .select-server {
        background-color: rgba(30, 31, 30, 1);
        border-radius: 10px;
    }

    .head-cell {
        text-align: center;
        margin-top: auto !important;
        margin-bottom: auto !important;

        color: ${props => props.theme.colors.lightblue};
    }

    .select-server {
        border: 1px solid rgb(115, 107, 94);
    }

    .selection-border-fix {
        border: 1px solid rgb(115, 107, 94);
    }

    .centertext {
        margin: auto;
        font-size: ${props => props.theme.fontSizes.medium};
    }

    .api-status {
        font-size: ${props => props.theme.fontSizes.medium};
    }

    .container1 {
        height: 90%;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
    }

    .bucket1 {
        width: 30%;
        margin-top: 2%;
    }

    td {
        margin: 0;
        padding: 0;
    }

    .bucket2 {
        margin-top: 2%;

        height: 60vh;
        width: 50vw;

        border-radius: 10px;

        background-color: rgba(30, 31, 30, 1);
        border: 1px solid rgb(115, 107, 94);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .button {
        font-size: 16px;
        text-align: center;
    }

    .input-table {
        border-collapse: collapse;
        width: 70%;
        border: 1px solid rgb(115, 107, 94);
    }

    input[type=text] {
        border: 0;
        width: 100%;
        height: 3vh;
        color: white;
        background-color: black;
        font-size: 1em;
    }

    .response {
        height: 40vh;
        width: 80%;
    }

    h1 {
        font-family: ${props => props.theme.fonts.alternative};
        text-align: left;
        padding-top: 20px;
        font-weight: 400;
        text-indent: 10%;
        font-size: 2em;

        width: 100%;
    }

    .exec-title {
        background-color: rgba(50, 51, 50, 1);
    }
    
    .selection-border {
        border: 1px solid rgba(80, 81, 80, 1);
        border-radius: 10px;
        overflow: clip;
        background-color: rgba(30, 31, 30, 1);
    }

    .selection-title {
        margin: 2% 0 0;
        background-color: rgba(50, 51, 50, 1);
        padding: 2% 0 0;
    }

    .response-overflow {
        max-height: 90%;
        width: 100%;
        text-align: left;
        padding: 1%;
        overflow: scroll;
        overflow-x: hidden;
        display: flex;
        flex-direction: column-reverse;
        scrollbar-width: thin;
    }

    .response-table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        user-select: text;
    }

    .response-table thead {
        border-bottom: 1px solid white;
    }

    .response-table td {
        border-right: 1px solid white;
    }

    .response-table thead td {
        border: 0;
    }

    .response-table td:last-child {
        border: 0;
        width: 50%;
        overflow-wrap: anywhere;
    }

    .response-table tbody tr:nth-child(even) {
        background-color: rgba(115, 107, 94, 0.1);
    }

    .response-table tbody:before {
        content: "@";
        display: block;
        line-height: 10px;
        text-indent: -99999px;
    }

    .response-table tbody tr:last-child {
        background-color: rgba(25, 26, 25, 0.7);
    }

    .response-table tbody tr:hover {
        background-color: rgba(25, 26, 25, 1);
    }

    user-select: none;

`