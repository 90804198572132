import {useEffect, useState} from "react";
import {WidgetViewStyled} from "./widgetview.styled";
import SelectServer from "../../widgets/select-server/selectserver";
import selectserver from "../../widgets/select-server/selectserver";
import ApiStatus from "../../widgets/api-status/apistatus";


const WidgetView = (props) => {
  const [response, setResponse] = useState([]);
  const [refreshRate, setRefreshRate] = useState(5)

  const handleFetch = async () => {
    try {
      return await fetch(`${props.central_server.protocol}://${props.central_server.address}:${props.central_server.port}/nodes/api`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "z_auth": props.authPass
        },
        body: JSON.stringify({"selector": "all"})
      })
        .then((res) => res.json())
        .then((json) => {
          setResponse(json);
        })
    } catch (error) {
      console.log('[CENTRAL FETCH ERROR] '.red + error)
    }
  }

  useEffect(() => {
    props.setMarquee('WIDGET VIEW');
  })

  useEffect(() => {
    handleFetch();
  }, [])

  useEffect(() => {
    const interval =  setInterval(() => {
      handleFetch();
    },refreshRate * 1000);
    return () => clearInterval(interval);
  });

  return (
    <WidgetViewStyled>
      {response[0] ? response[0].id === 0 && response[0].response.full_encryption ? (<header><p className={'full'}>FULL NETWORK ENCRYPTION</p></header>): (<header><p className={'unenc'}>NETWORK UNENCRYPTED</p></header>) : ''}

      <div className={'container1'}>
        {response.map((element) => {
          return (<ApiStatus key={2137 + Math.random() * Math.random()} available_nodes={props.availableNodes} setMarquee={props.setMarquee} central_server={props.central_server} server_data={element} authPass={props.authPass} />)
        })}
      </div>
    </WidgetViewStyled>
)}

export default WidgetView;