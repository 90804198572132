import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import configFile from './config.json';

const viewConfig = configFile[0].list

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <App configFile={viewConfig} />
  </React.StrictMode>
);