import styled from 'styled-components';

export const SvcViewStyled = styled.div`
    color: white;
    text-align: center;
    height: 100%;
    width: 100%;
    margin-top: 0;

    header {
        background-color: ${props => props.theme.colors.gray};
        font-family: ${props => props.theme.fonts.technical};
        width: 40%;
        display: block;
        margin-left: 60%;
    }

    h1 {
        text-align: left;
        padding: 20px;
        font-family: ${props => props.theme.fonts.alternative};
        margin: 0;
        font-weight: 400;
    }

    .centertext {
        text-align: left;
        margin: auto;
        padding: 1%;
        font-size: ${props => props.theme.fontSizes.medium};
    }

    .container1 {
        display: flex;
        width: 95%;
        margin-top: 5vh;
        justify-content: space-between;
        flex-direction: row;
    }

    .bucket2 {
        width: 30vw;
        height: 50%;
        position: static;
        margin: 1%;

        background-color: rgba(30, 31, 30, 1);
        border: 1px solid rgba(80, 81, 80, 1);
        border-radius: 10px;

        display: flex;
        flex-direction: column;

        font-family: ${props => props.theme.fonts.regular};

        overflow-y: scroll;
        max-height: 70vh;
        -ms-overflow-style: none;
        scrollbar-width: none;
        box-sizing: border-box;
    }

    .bucket1::-webkit-scrollbar {
        display: none;
    }

    .head-row {
        display: flex;
        flex-direction: row;
        text-align: left;

        height: 5vh;

        background-color: rgba(50, 51, 50, .2);
        border-bottom: 1px solid ${props => props.theme.colors.gray};
    }

    .head-section {
        border-bottom: 1px solid ${props => props.theme.colors.gray};
    }

    .service-list {
        width: 90%;
        margin: 0 auto 0 auto;
    }

    .head-cell {
        text-align: center;
        margin-top: auto !important;
        margin-bottom: auto !important;

        color: ${props => props.theme.colors.lightblue};
    }

    .head-cell:nth-child(1) {
        width: 20%;
    }

    .head-cell:nth-child(2) {
        width: 10%;
        padding-left: 10%;
    }

    .head-cell:nth-child(3) {
        width: 10%;
        padding-left: 30%;
    }

    .head-cell:nth-child(4) {
        width: 10%;
    }

    .head-cell:nth-child(5) {
        width: 10%;
    }

    h1 {
        width: 100%;
        background-color: rgba(50, 51, 50, 1);
        padding: 2% 0 0;
        text-indent: 10%;
        margin-top: 2%;
    }
    
    .margindown {
        margin-bottom: 5%;
    }

    .service-list {
        border-style: hidden;
        margin-bottom: 5%;
    }

    .bucket1 {
        width: 50%;
        position: static;
        background-color: rgba(30, 31, 30, 1);
        border-radius: 10px;

        margin-left: 3%;
        border: 1px solid gray;
        box-sizing: border-box;
        
        overflow: clip;

        font-family: ${props => props.theme.fonts.regular};
    }

    .log-title {
        margin: 0;
        padding: 0;
    }

    .log-title {
        width: 100%;
        //border-bottom: 1px solid white;
        text-align: left;
        font-family: ${props => props.theme.fonts.technical};
        font-size: 1em;
    }

    td > * {
        margin: 0;
        padding: 0;
    }

    td:nth-child(0) {
        text-align: center;
        border-right: 1px solid white;
    }

    tr {
        text-align: center;
    }

    .green {
        color: ${props => props.theme.colors.green};
    }

    .log-table {
        border-collapse: collapse;
        border: 1px solid rgb(115, 107, 94);
        text-align: center;
        width: 100%;
        font-family: ${props => props.theme.fonts.technical};
    }

    .log-table td:nth-child(1) {
        width: 20%;
    }

    .log-table td:nth-child(2) {
        width: 20%;
    }

    .log-table tbody td:nth-child(3) {
        padding-left: 1%;
        text-align: left;
    }

    .log-table td:nth-child(3) {
        border-left: 1px solid white;
    }

    .log-table tbody tr:nth-child(odd) {
        //border-bottom: 1px solid rgb(100, 100, 100);
        background-color: rgba(100, 100, 100, 0.2);
    }

    .log-table tr:nth-child(even) {
        //border-bottom: 1px solid rgba(100, 100, 100, 0.5);
        background-color: rgba(100, 100, 100, 0.1);
    }

    .log-table thead {
        text-align: center;
        border-bottom: 1px solid white;
    }

    .log-table tbody {
        text-align: center;
    }

    .log-content {
        width: 98%;
        padding: 1%;
        height: 70vh;
    }

    .log-child {
        margin: 0;
    }

    .svc-scrollable {
        overflow-y: scroll;
        scrollbar-width: none;
        overflow-x: hidden;
    }

    .title {
        margin-bottom: 1%;
    }

    user-select: none;
`