import styled from 'styled-components';


export const SidebarStyled = styled.div`
      min-width: 10%;
      font-family: ${props => props.theme.fonts.technical};
      font-weight: 200;
      font-size: 22px;
      background-color: rgba(30, 31, 30, 1);
      border-right: 1px solid #484e51;
      user-select: none;
      
      // -webkit-box-shadow: inset -26px 0px 38px -17px ${props => props.theme.colors.crimson};
      // -moz-box-shadow: inset -26px 0px 38px -17px ${props => props.theme.colors.crimson};
      // box-shadow: inset -26px 0px 38px -17px ${props => props.theme.colors.crimson};
  
      p {
        text-align: left;
        color: white;
      }
`