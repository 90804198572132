import styled from "styled-components";
import dotted from "../../../assets/bg_dotted.png"

export const ServerSummaryStyled = styled.div`
    font-family: ${props => props.theme.fonts.alternative};
    width: 500px;
    text-align: left;
    display: flex;
    flex-direction: column;
    background-color: rgba(30, 31, 30, 1);
    overflow: hidden;

    border-radius: 10px;

    .flex1 {
      display: flex;
      flex-direction: column;
      margin-right: 1%;
      margin-bottom: 1%;
    }

    h1 {
        font-size: ${props => props.theme.fontSizes.medium};
        font-weight: 200;
        letter-spacing: 1px;
        margin-top: 0;
        padding-bottom: 3%;
        padding-top: 3%;
        padding-left: 20px;
        border-bottom: 1px solid rgba(201, 201, 201, 0.2);
    }
  
    .stat-title {
        font-size: ${props => props.theme.fontSizes.small};
    }
    
    .update-info {
        margin: 0;
        padding-top: 20px;
        padding-right: 20px;
        padding-bottom: 5px;
        color: gray;
        
        text-align: right;
    }
  
    h3 {
      font-family: ${props => props.theme.fonts.poppins};
      font-weight: 500;
      margin-top: 2%;
      margin-bottom: 0;
      font-size: 2em;
      margin-left: 10%;
      color: rgba(255, 255, 255, .7);
    }
    
    .chart {
        margin: 0;
        padding: 0;
        max-width: 1vw;
    }
    
    .chart svg {
        background-image: url(${dotted});
    }

    .info {
      border: 1px solid ${props => props.theme.colors.gray};
      width: 80%;
      margin-left: 1%;
      display: flex;
      flex-direction: column;
      margin-bottom: 2%;
    }
  
    .info-element {
      border-bottom: solid ${props => props.theme.colors.gray} 1px;
      width: 100%;
      height: 1em;
    }
  
    .info-element:last-child {
      border: 0;
    }
  
    .info-element p {
      margin: 0;
    }
  
    .info-bar {
      background-color: rgb(99, 161, 228);
      height: 100%;
    }
  
    .rounded {
      border-radius: 10px;
    }
  
    p {
      margin-top: 0;
      margin-bottom: 0;

      font-family: ${props => props.theme.fontSizes.alternative};
      font-weight: 300;
    }
  
    .non-bar {
      width: 100%;
      text-align: center;
      margin: 0;
      padding: 0;
      
    }
  
    .info table td:nth-child(2) {
      border-left: 1px solid gray;
    }
  
    td p {
      padding: 0;
      margin: 0;
    }

    //.circle {
    //  width: 20%;
    //  height: 40%;
    //  margin: 0;
    //}
  
    td:nth-child(1) {
      width: 20%;
    }

    border: 1px solid ${props => props.theme.colors.gray};
    box-sizing: border-box;
`