import {ServerViewStyled} from "./srv-view.styled";
import ServerStatus from "../../widgets/server-status/serverstatus";
import {useEffect, useState} from "react";
import ServerSummary from "../../widgets/server-summary/serversummary";

const ServerView = (props) => {
  const [response, setResponse] = useState([]);
  const [refreshRate, setRefreshRate] = useState(5);
  const [manualFetch, setManualFetch] = useState(false);
  const [avgCPUHistory, setCPUHistory] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [avgRAMHistory, setRAMHistory] = useState([0]);
  const [next_update, setNextUpdate] = useState(0);
  const [latest_update, setLatestUpdate] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  let avgCPU = 0;
  let avgRAM = 0;

  props.setMarquee(`STATUS / SERVER INFORMATION`);

  const handleFetch = async () => {
    if (isFetching) return;
    setIsFetching(true);
    try {
      return await fetch(`${props.central_server.protocol}://${props.central_server.address}:${props.central_server.port}/nodes`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "z_auth": props.authPass
        },
        body: JSON.stringify({"selector": "all"})
      })
        .then((res) => {
          setNextUpdate(parseInt(res.headers.get('next_update')));
          return res.json()})
        .then((json) => {
          setResponse(json);
          setCPUHistory([...avgCPUHistory, avgCPU]);
          setRAMHistory([...avgRAMHistory, avgRAM]);
          setIsFetching(false);
        })
    } catch (error) {
      console.log('[CENTRAL FETCH ERROR] '.red + error)
    }
  }

  const getOS = () => {
    if (window.navigator.platform.indexOf("Win") !== -1) {
      return("win");
    } else if (window.navigator.platform.indexOf("Mac") !== -1) {
      return("mac");
    } else if (window.navigator.platform.indexOf("Linux") !== -1) {
      return("linux");
    } else {
      return("unknown");
    }
  }

  useEffect(() => {
    handleFetch();
  }, [])

    useEffect(() => {
      if (getOS() !== "win") {
        const interval =  setInterval(() => {
          const current_date = new Date(Date.now());
          const offset = new Date((current_date.getTimezoneOffset() * 60000)).getTime();
          const translated_date = new Date(current_date.getTime() + offset).getTime();

          // console.log(`
          //     n: ${typeof next_update} ${next_update} ${new Date(next_update)},
          //     c: ${typeof current_date.getTime()} ${current_date.getTime()} ${new Date(current_date)},
          //     t: ${typeof translated_date} ${translated_date} ${new Date(translated_date)},
          //     l: ${typeof latest_update} ${latest_update}
          //   `);

          if (current_date.getTime() >= next_update && next_update > 0 && next_update !== latest_update) {
            setLatestUpdate(parseInt(next_update));
            // console.log(`
            //     n: ${typeof next_update} ${next_update} ${new Date(next_update)},
            //     c: ${typeof current_date} ${current_date} ${new Date(current_date)},
            //     t: ${typeof translated_date} ${translated_date} ${new Date(translated_date)},
            //     l: ${typeof latest_update} ${latest_update}
            //   `);
            handleFetch();
          }
        },1000);
        return () => clearInterval(interval);
      } else {
        setManualFetch(true);
        const interval =  setInterval(() => {
          handleFetch();
        }, refreshRate * 1000);
        return () => clearInterval(interval);
      }
    });

  let latestElementId= -1;

  let sumCpu = 0;
  let sumRam = 0;

  if (avgCPUHistory.length === 10) {
    setCPUHistory(avgCPUHistory.slice(1))
  }

  for (const element of response) {
    if (element.id === latestElementId) {
      return 0;
    } else {
      latestElementId++;
      sumCpu += element.response.load;
      sumRam += element.response.used_mem;
    }
    if (latestElementId === response.length - 1) {
      avgCPU += sumCpu / (latestElementId + 1);
      avgRAM += sumRam / (latestElementId + 1);
      sumCpu = 0;
      sumRam = 0;
      latestElementId = 0;
    }
  }

  return (
    <ServerViewStyled>

      <div className={"container1"}>
        <div className={'bucket1'}>
          {/*<div className={'title'}><p>SERVER_LIST</p></div>*/}
          <div className={'srv-scrollable'}>
            <ServerStatus response={response} central_server={props.central_server} />
          </div>

        </div>
        <div className={'bucket2'}>
          <ServerSummary manual_fetch={manualFetch} server_data={response} refreshRate={refreshRate} avgCPU={avgCPU} avgRAM={avgRAM} avgCPUHistory={avgCPUHistory} avgRAMHistory={avgRAMHistory} />
        </div>
      </div>
    </ServerViewStyled>
  )

}

export default ServerView;