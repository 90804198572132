import { SidebarStyled } from "./sidebar.styled";
import SidebarElement from "./sidebar-element/sidebar-element";

const Sidebar = (props) => (
    <SidebarStyled>
        {props.viewConfig.map((element) =>{
            if (!element.hidden) {
                return (
                  <SidebarElement currentView={props.currentView} setView={props.setView} key={element.id} element={element}/>)
            }
        })}
    </SidebarStyled>
)

export default Sidebar;