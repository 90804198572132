import styled from 'styled-components';

export const WidgetViewStyled = styled.div`
    color: white;
    text-align: center;
    height: 100%;
    width: 100%;

    header {
      font-family: ${props => props.theme.fonts.technical};
      width: 100%;
      min-height: 50px;
      display: block;
    }
  
    .full {
      background-color: ${props => props.theme.colors.blue};
      margin: auto;
      font-size: ${props => props.theme.fontSizes.medium};
    }
    
    .unenc {
        margin: auto;
        font-size: ${props => props.theme.fontSizes.medium};
        background-color: ${props => props.theme.colors.reddim};
    }
  
    .api-status {
      font-size: ${props => props.theme.fontSizes.medium};
    }
    
    .circle {
        width: 40%;
        height: 40%;
    }
    
    .container1 {
        height: 93%;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-width: thin;
        scrollbar-color: ${props => props.theme.colors.blue} gray;
    }
    
    .container1 > * {
        margin: 1% 1% 0 5%;
    }
    
    .bucket1 {
        height: 50%;
        width: 30%;
    }
    
    td {
        margin: 0;
        padding: 0;
    }
  
    
    `