import styled from 'styled-components';

export const DefaultViewStyled = styled.div`
    color: white;
    text-align: center;
    height: 100%;
    width: 100%;

    header {
      background-color: ${props => props.theme.colors.blue};
      font-family: ${props => props.theme.fonts.technical};
      width: 100%;
      min-height: 50px;
      display: block;
    }
  
    .centertext {
      margin: auto;
      font-size: ${props => props.theme.fontSizes.medium};
    }
  
    .api-status {
      font-size: ${props => props.theme.fontSizes.medium};
    }
    
    .circle {
        width: 40%;
        height: 40%;
    }
  
    
    `