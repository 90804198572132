import {useEffect, useState} from "react";
import {ExecuteViewStyled} from "./executeview.styled";
import SelectServer from "../../widgets/select-server/selectserver";


const ExecuteView = (props) => {
  const [selectedServers, setSelectedServers] = useState([]);
  const [commandString, setCommandString] = useState('')
  const [cmdresponse, setResponse] = useState([])

  useEffect(() => {
    props.setMarquee('MANAGEMENT / REMOTE EXECUTION')
  })

  const handleFetch = async (selected_id) => {
    if (selected_id !== 0 && commandString) {
      try {
        return await fetch(`${props.central_server.protocol}://${props.central_server.address}:${props.central_server.port}/nodes/execute`, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "z_auth": props.authPass
          },
          body: JSON.stringify({
            "selector": selected_id,
            "z_command": commandString
          })
        })
          .then((res) => res.json())
          .then((json) => {
            const date = new Date().toJSON();
            return {
              "node": json[0].name,
              "response": json[0].response,
              "date": date
            };
          })
      } catch (error) {
        console.log('[CENTRAL FETCH ERROR] '.red + error)
      }
    } else if (selected_id === 0 && commandString) {
      try {
        return await fetch(`${props.central_server.protocol}://${props.central_server.address}:${props.central_server.port}/execute`, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "z_auth": props.authPass
          },
          body: JSON.stringify({
            "selector": selected_id,
            "z_command": commandString
          })
        })
          .then((res) => res.json())
          .then((json) => {
            const date = new Date().toJSON();
            return {
              "node": json.name,
              "response": json.response,
              "date": date
            };
          })
      } catch (error) {
        console.log('[CENTRAL FETCH ERROR] '.red + error)
      }
    } else {

    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    let response_copy = [...cmdresponse]
    for (const element of selectedServers) {
      response_copy.push(await handleFetch(element))
    }
    setResponse(response_copy);
  }

  return (
    <ExecuteViewStyled>
        <header><p className={'centertext'}>REMOTE EXECUTION</p></header>
      <div className={'container1'}>
        <div className={'bucket1'}>
          <div className={'selection-border'}>
            <SelectServer availableNodes={props.availableNodes} selectedServers={selectedServers} setSelectedServer={setSelectedServers}/>
          </div>
        </div>
        <div className={'bucket2'}>
          <h1 className={'exec-title'}>Execute command</h1>
          <form onSubmit={handleSubmit}>
            <center><table className={'input-table'} border={0}>
              <tbody>
                <tr>
                  <td>
                    <input name='command' type={'text'} value={commandString} onChange={(e => setCommandString(e.target.value))}></input>
                  </td>
                  <td>
                    <div className={'button'} type={'submit'} onClick={handleSubmit} value={commandString}>EXECUTE</div>
                  </td>
                </tr>
              </tbody>
            </table></center>
          </form>
          <center><div className={'response'}><div className={'response-overflow'}>
            <table border={0} className={'response-table'}>
              <thead>
                <tr>
                  <td>Date</td>
                  <td>Node</td>
                  <td>Output</td>
                </tr>
              </thead>
              <tbody>
                {cmdresponse.map(element => {
                  return (
                    <tr>
                      <td>{element.date}</td>
                      <td>{element.node}</td>
                      <td>{element.response}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div></div></center>
        </div>
      </div>
    </ExecuteViewStyled>
)}

export default ExecuteView;