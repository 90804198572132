import styled from 'styled-components';

export const ServerViewStyled = styled.div`
    color: white;
    text-align: center;
    height: 100%;
    width: 100%;
    
    .container1::-webkit-scrollbar {
      display: none;
    }

    header {
        background-color: ${props => props.theme.colors.gray};
        font-family: ${props => props.theme.fonts.technical};
        width: 40%;
        display: block;
        margin-left: 60%;
    }

    .centertext {
        text-align: left;
        margin: auto;
        padding: 1%;
        font-size: ${props => props.theme.fontSizes.medium};
    }

    .square {
        margin-top: 10vh;
        margin-left: 20vw;
        width: 200px;
        height: 200px;
        background-color: ${props => props.theme.colors.purple};

        animation-name: spin;
        animation-duration: 5000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    .container1 {
        display: flex;
        width: 95%;
        margin-top: 5vh;
        justify-content: space-evenly;
        
    }

    .bucket1 {
        width: 60%;
        position: static;
        margin-left: 1%;

        display: flex;
        flex-direction: column;

        font-family: ${props => props.theme.fonts.regular};
      
        height: 70vh;
        -ms-overflow-style: none;
        scrollbar-width: none;
      
        background-color: rgba(30, 31, 30, 1);
        border: 1px solid rgba(80, 81, 80, 1);
      
        border-radius: 10px;
        overflow: hidden;
    }
  
    .title {
      margin-bottom: 1%;
    }
    
    .bucket1::-webkit-scrollbar {
      display: none;
    }
  
    .bucket2 {
      width: 50%;
      height: 100%;
      position: static;

      display: flex;
      justify-content: right;

      font-family: ${props => props.theme.fonts.regular};
    }
  
    .srv-scrollable {
      overflow-y: scroll;
      scrollbar-color: rgba(80, 81, 80, 1) rgba(30, 31, 30, 1);
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  
  user-select: none;
`