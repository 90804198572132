import { ThemeProvider } from 'styled-components'

/*
    Fonts:
        - Roboto Mono (400)
        - Oswald (200, 400)
        - Barlow Semi Condensed (400)

        font-family: 'Barlow Semi Condensed', sans-serif;
        font-family: 'Oswald', sans-serif;
        font-family: 'Roboto Mono', monospace;
        font-family: 'Share Tech Mono', monospace;
        font-family: 'Noto Sans', sans-serif;
        font-family: 'Poppins', sans-serif;
        font-family: 'Montserrat', sans-serif;
 */

const theme = {
    colors: {
        green: '#50ad4b',
        blue: 'rgb(17, 71, 145)',
        red: '#e3372b',
        black: 'rgba(11, 12, 13, 1)',
        caution: '#d61818',
        yellow: '#dbc51a',
        purple: '#7a44c9',
        gray: 'rgb(105, 105, 105)',
        reddim: '#ad150a',
        lightblue: 'rgb(167, 221, 255)',
        crimson: 'rgba(255, 20, 20, 0.2)'
    },
    fontSizes: {
        small: '1em',
        medium: '2em',
        large: '3em'
    },
    fonts: {
        regular: '\'Roboto Mono\', monospace',
        alternative: '\'Barlow Semi Condensed\', sans-serif',
        technical: '\'Oswald\', sans-serif',
        mono: '\'Share Tech Mono\', monospace',
        noto: '\'Noto Sans\', sans-serif;',
        poppins: 'font-family: \'Poppins\', sans-serif;',
        montserrat: 'font-family: \'Montserrat\', sans-serif;'
    }
}
const Theme = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
export default Theme;