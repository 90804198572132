import {useEffect, useState} from "react";
import {ApiViewStyled} from "./apiview.styled";
import ApiStatus from "../../widgets/api-status/apistatus";


const ApiView = (props) => {
  const [response, setResponse] = useState([]);
  const [refreshRate, setRefreshRate] = useState(5)

  const handleFetch = async () => {
    try {
      return await fetch(`${props.central_server.protocol}://${props.central_server.address}:${props.central_server.port}/nodes/api`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "z_auth": props.authPass
        },
        body: JSON.stringify({"selector": "all"})
      })
        .then((res) => res.json())
        .then((json) => {
          setResponse(json);
        })
    } catch (error) {
      console.log('[CENTRAL FETCH ERROR] '.red + error)
    }
  }

  const filterOut = () => {
    return response.filter((obj, index) => {
      if (obj) {
        return index === response.findIndex(o => obj.id === o.id && obj.name === o.name);
      }
    })
  }

  useEffect(() => {
    props.setMarquee('STATUS / API STATUS');
  })

  useEffect(() => {
    setResponse([]);
    handleFetch();
    setResponse(filterOut())
  }, [])

  useEffect(() => {
    const interval =  setInterval(() => {
      handleFetch();
      setResponse(filterOut())
    },refreshRate * 1000);
    return () => clearInterval(interval);
  });

  return (
    <ApiViewStyled>
      {response[0] ? response[0].id === 0 && response[0].response.full_encryption ? (<header><p className={'full'}>FULL NETWORK ENCRYPTION</p></header>): (<header><p className={'unenc'}>NETWORK UNENCRYPTED</p></header>) : <header><p className={'greyed-out'}>RETRIEVING DATA</p></header>}

      <center><div className={'container1'}>
        {/*{response.map((element) => {*/}
        {/*  return (<ApiStatus key={2137 + Math.random() * Math.random()} available_nodes={props.availableNodes} setMarquee={props.setMarquee} central_server={props.central_server} server_data={element} authPass={props.authPass} />)*/}
        {/*})}*/}
        <h1 className={'api-title'}>API Status</h1>
        <div border={0} className={'api-table'}>
            <div className={'head-row'}>
              <div className={'head-cell'}><p>ID</p></div>
              <div className={'head-cell'}><p>Name</p></div>
              <div className={'head-cell'}><p>Execution</p></div>
              <div className={'head-cell'}><p>Encryption</p></div>
              <div className={'head-cell'}><p>Self refresh</p></div>
            </div>
            <div className={'api-content'}><div>
            {response.map((element => {
              return (<div className={'body-row'} key={2137 + Math.random() * Math.random()}>
                <div className={'body-cell'}><p>{element.id}</p></div>
                <div className={'body-cell'}><p>{element.name}</p></div>
                <div className={'body-cell'}><p>{element.response.execution ? 'Enabled' : 'Disabled'}</p></div>
                <div className={'body-cell'}><p>{element.response.encrypted ? 'Enabled' : 'Disabled'}</p></div>
                <div className={'body-cell'}><p>{element.response.self_refresh} seconds</p></div>
              </div>)
            }))}
          </div>
          </div>
        </div>
      </div></center>
    </ApiViewStyled>
)}

export default ApiView;