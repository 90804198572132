import styled from "styled-components";

export const ServerStatusStyled = styled.div`
    font-family: ${props => props.theme.fonts.alternative};
    font-weight: 400;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
  
    &:first-child {
      margin: 0;
    }
    
    .extended-row {
        background-color: rgba(15, 16, 15, .4);
    }
  
    .server-line td {
      padding-top: 1%;
      padding-bottom: 1%;
    }
    
    h1 {
        padding: 2%;
    }
  
    .server-line {
      background-color: rgba(17, 71, 145, 1);
    }
  
    .progress-parent {
      color: transparent;
      font-size: 4px;
      padding-bottom: 0;
    }
  
    thead tr:nth-child(2) {
      color: transparent;
    }
  
    .progress-parent {
      padding-top: 1%;
      padding-bottom: 1%;
      text-indent: 1%;
    }
  
    .progress-desc {
      font-size: 14px;
      color: white;
      margin: 0;
      font-family: ${props => props.theme.fonts.regular}
    }

    h1 {
        font-size: ${props => props.theme.fontSizes.medium};
        font-weight: 200;
        letter-spacing: 1px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 1%;
    }

    .flex1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 1%;
        margin-bottom: 1%;
    }

    .info {
        border: 1px solid ${props => props.theme.colors.gray};
        width: 70%;
        margin-left: 1%;
        display: flex;
        flex-direction: column;
        margin-bottom: 2%;
    }
  
    .info-element {
      border-bottom: solid ${props => props.theme.colors.gray} 1px;
      width: 100%;
      min-height: ${props => props.theme.fontSizes.small};
    }
  
    .red {
      color: ${props => props.theme.colors.red} !important;
    }
  
    .info-element strong {
      color: ${props => props.theme.colors.reddim};
    }
  
    .info-element:last-child {
      border: 0;
    }
    
    .info-element p {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 1%;
    }
  
    .percentage-row {
      color: transparent;
    }
  
    .encryption-icon {
      font-size: 16px;
      display: flex;
      align-content: flex-end;
    }
  
    .encryption-icon p {
      text-indent: .2em;
      margin: 0;
    }
  
    .white {
      color: white;
    }
  
    .encryption-icon img {
      object-fit: contain;
    }

    strong {
      font-family: ${props => props.theme.fonts.alternative};
    }
  
    .mem-percent {
      background-color: rgba(194, 149, 45, 1);
    }
  
    thead tr {
        height: 3em;  
        color: rgb(167, 221, 255);
        border-top: 1px solid rgba(201, 201, 201, 0.2);
        font-size: 16px;
        font-family: ${props => props.theme.fonts.noto};
    }
  
    .cpu-percent {
      background-color: rgba(45, 194, 122, 1);
    }
  
    td:first-child {
      width: 10%;
      text-align: center;
    }
  
    table {
      border-collapse: collapse;
    }

    .empty-row {
      color: transparent;
      font-size: 10px;
      background-color: rgba(30, 31, 30, 1);
    }
  
    .encrypted {
        color: white;
    }
  
    tr {
      background-color: rgba(31, 31, 31, .4);
    }
  
    .unencrypted {
      color: indianred;
    }
  
    .info-element td:nth-child(1) {
      border-right: 1px solid rgb(115, 107, 94);
    }

    box-sizing: border-box;
`