const authenticate = async (password, element, hSet, updateNodes) => {
  if (password === 'override') {
    return 1;
  }
  try {
    return await fetch(`${element.protocol}://${element.address}:${element.port}/login`, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "z_auth": password
      }
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.result === true) {
          hSet('')
          updateNodes(json.nodes)
          return 1;
        } else {
          return 0;
        }
      })
  } catch(error) {
    console.log('[CENTRAL FETCH ERROR] '.red + error)
  }
}

export default authenticate;