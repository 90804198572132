import './App.css';
import Sidebar from "./components/main-sidebar/sidebar";
import React, { useState, useRef } from 'react';
import Theme from "./theme";
import Renderer from "./components/renderer/renderer";
import Authenticator from "./components/authenticator/authenticator";
import authenticate from "./helpers/authenticate";
import authenticator from "./components/authenticator/authenticator";
import centralServer from "./central.json"
import zebservlogo from "./assets/zebserv-de4.png";

const App = (props) => {
    const [currentView, setView] = useState(-1);
    const [currentVisibility, setVisibility] = useState(0);
    const [marqueeText, setMarquee] = useState('AUTHENTICATE');
    const [availableNodes, setAvailableNodes] = useState([]);

    const [authPass, setAccessKey] = useState('');
    const handleSubmit = async (event) => {
      event.preventDefault();
      setVisibility(await authenticate(authPass, centralServer, setMarquee, setAvailableNodes));
    }

    const MainContainer = () => (
        <div className={`main-container`}>
            <Sidebar currentView={currentView} setView={setView} viewConfig={props.configFile} />
            <div className={'viewSpace'}>
                <Renderer setVisibility={setVisibility} setMarquee={setMarquee} authPass={authPass} currentView={currentView} central_server={centralServer} availableNodes={availableNodes} />
            </div>
        </div>
    )

  return (
    <>
        <header>
          <div className={'logo'}>
          {/*  /!*<img src={zebservlogo} alt={"Zebserv LOGO"}></img>*!/*/}
          </div>
          <div className={'header-info'}>
            {/*<div className={'login-name'}>*/}
            {/*  <p></p>*/}
            {/*</div>*/}
            <div className={'marquee'}>
              <p>{marqueeText}</p>
            </div>
          </div>
        </header>
        <Theme>
            {currentVisibility === 1 ? <MainContainer /> : <Authenticator authPass={authPass} setAccessKey={setAccessKey} handleSubmit={handleSubmit} />}
        </Theme>
    </>
  );
}

export default App;
