import {SvcViewStyled} from "./svc-view.styled";
import ServerStatus from "../../widgets/server-status/serverstatus";
import React, {useEffect, useState} from "react";
import ServerSummary from "../../widgets/server-summary/serversummary";
import ServiceStatus from "../../widgets/service-status/servicestatus";
import {ProgressBar} from "../../widgets/server-status/progressbar.styled";
import SelectServer from "../../widgets/select-server/selectserver";

const ServiceView = (props) => {
  const [response, setResponse] = useState([]);
  const [refreshRate, setRefreshRate] = useState(5);
  const [logger, setLog] = useState([]);
  const [selectedServer, setSelectedServers] = useState([0]);

  const handleFetch = async () => {
    try {
      return await fetch(`${props.central_server.protocol}://${props.central_server.address}:${props.central_server.port}/nodes/services`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "z_auth": props.authPass
        },
        body: JSON.stringify({"selector": selectedServer[0]})
      })
      .then((res) => res.json())
      .then((json) => {
        let uniqueJson = json.filter((element, index) => {
          return json.indexOf(element) === index;
        });

        setResponse(uniqueJson);
      })
    } catch (error) {
      console.log('[CENTRAL FETCH ERROR] '.red + error)
    }
  }

  useEffect(() => {
    handleFetch();
    props.setMarquee('MANAGEMENT / SERVICE VIEW')
  }, [])

  useEffect(() => {
    handleFetch();
  }, [selectedServer])

  useEffect(() => {
    const interval =  setInterval(() => {
      handleFetch();
    },refreshRate * 1000);
    return () => clearInterval(interval);
  });

  return (
   <SvcViewStyled>
     <div className={'container1'}>
       <div className={'bucket1'}>
         <h1 className={'margindown'}>Services / {response[selectedServer] ? response[selectedServer].name : ''}</h1>
         <div className={'service-list'}>
           <div className={'head-section'}>
             <div className={'head-row'}>
               <p className={'head-cell'}>Name</p>
               <p className={'head-cell'}>Status</p>
               <p className={'head-cell'}>Kill</p>
               <p className={'head-cell'}>Start</p>
               <p className={'head-cell'}>Stop</p>
             </div>
           </div>
           <div className={'body-section'}>
               {
                 response[selectedServer] ? response[selectedServer].response.map(service => {
                   return (<ServiceStatus logger={logger} setLog={setLog} key={2137 + Math.random() * Math.random()} server_id={response[selectedServer].id} authPass={props.authPass} central_server={props.central_server} server_data={service} server_name={response[selectedServer].name} />)
               }) : <div>
                   <p>No services</p>
                 </div>}
           </div>
         </div>
       </div>
       <div className={'bucket2'}>
          <SelectServer single={1} authPass={props.authPass} central_server={props.central_server} availableNodes={props.availableNodes} selectedServers={selectedServer} setSelectedServer={setSelectedServers} />
       </div>
     </div>
   </SvcViewStyled>
  )

}

export default ServiceView;