import {DefaultViewStyled} from "./defaultview.styled";
import ServerStatus from "../../widgets/server-status/serverstatus";
import {useEffect, useState} from "react";
import ServerSummary from "../../widgets/server-summary/serversummary";


const DefaultView = (props) => {
  useEffect(() => {
    props.setMarquee('DEFAULT VIEW')
  })

  return (
    <DefaultViewStyled>
        <header><p className={'centertext'}>WELCOME BACK</p></header>
      <div className={'container1'}>
        <h1>Authentication success</h1>
      </div>
    </DefaultViewStyled>
)}

export default DefaultView;