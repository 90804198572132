import styled from 'styled-components';

export const ApiViewStyled = styled.div`
    color: white;
    text-align: center;
    height: 100%;
    width: 100%;

    header {
        font-family: ${props => props.theme.fonts.technical};
        width: 100%;
        margin: auto;
        min-height: 50px;
        display: block;
    }

    .greyed-out {
        margin: auto;
        font-size: ${props => props.theme.fontSizes.medium};
        background-color: ${props => props.theme.colors.gray};
    }

    .full {
        background-color: ${props => props.theme.colors.blue};
        margin: auto;
        font-size: ${props => props.theme.fontSizes.medium};
    }

    .unenc {
        margin: auto;
        font-size: ${props => props.theme.fontSizes.medium};
        background-color: ${props => props.theme.colors.reddim};
    }

    .api-status {
        font-size: ${props => props.theme.fontSizes.medium};
    }

    .circle {
        width: 40%;
        height: 40%;
    }
    
    .api-title {
        text-indent: 3%;
    }

    .container1 {
        background-color: rgba(30, 31, 30, 1);
        border: 1px solid gray;
        box-sizing: border-box;
        margin-top: 5%;
    }

    .api-table {
        display: flex;
        flex-direction: column;
    }

    .head-row, .body-row {
        display: flex;
        text-align: left;
    }

    .body-row {
        border-radius: 10px;
    }

    .body-cell, .head-cell {
        margin: auto;
    }

    .body-cell p {
        margin: auto;
    }

    .head-cell {
        color: ${props => props.theme.colors.lightblue}
    }

    .api-table .head-cell {
        font-weight: 400;
        border-bottom: 1px solid rgba(201, 201, 201, 0.2);
        text-align: left;
    }

    .api-content {
        overflow-x: clip;
        overflow-y: scroll;
        scrollbar-width: none;
        display: inline-block;
        height: 40vh;
        scrollbar-color: ${props => props.theme.colors.blue} gray;
    }

    .api-table .body-row {
        height: 4vh;
    }

    .api-content .body-cell:nth-child(1), .api-table .head-cell:nth-child(1) {
        width: 10vw;
        text-align: center;
    }

    .api-content .body-cell:nth-child(2), .api-table .head-cell:nth-child(2) {
        width: 30vw;
    }

    .api-content .body-cell:nth-child(3), .api-table .head-cell:nth-child(3) {
        width: 20vw;
    }
    .api-content .body-cell:nth-child(4), .api-table .head-cell:nth-child(4) {
        width: 20vw;
    }
    .api-content .body-cell:nth-child(5), .api-table .head-cell:nth-child(5) {
        width: 15vw;
    }

    .api-table .head-cell {
        background-color: rgba(50, 51, 50, .2);
    }


    .api-table .body-row:nth-child(even) {
        background-color: rgba(101, 101, 101, 0.1);
    }

    h1 {
        font-size: ${props => props.theme.fontSizes.medium};
        font-family: ${props => props.theme.fonts.alternative};
        padding: 20px;
        font-weight: 200;
        letter-spacing: 1px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 1%;
        text-align: left;
    }

    .container1 {
        min-height: 60vh;
        max-height: 60vh;
        border-radius: 10px;
        width: 80vw;
        display: flex;
        flex-direction: column;
    }

    .container1 > * {
        margin: 1% 1% 0 1%;
    }

    .bucket1 {
        height: 50%;
        width: 30%;
    }


`