const Authenticator = (props) => (
    <center><div className={'authenticator-parent'}>
        <center><div className={'authenticator'}>
            <form onSubmit={props.handleSubmit}>
                <p>AUTHENTICATION REQUIRED</p>
                <input name='accesskey' type={'password'} value={props.authPass} onChange={(e => props.setAccessKey(e.target.value))} maxLength={50}></input>
                {/*<br /><br />*/}
                {/*<input type={'button'} onClick={props.handleSubmit} value={"AUTHENTICATE"}></input>*/}
            </form>
        </div></center>
    </div></center>
)

export default Authenticator;