import styled from 'styled-components';

export const SidebarElementStyled = styled.div`
    margin: 0;
    text-align: left;
    font-size: 18px;
    max-width: 10vw;
    overflow-x: hidden;
    overflow-wrap: break-word;
  
    &:nth-child(1) {
      margin-top: 10%;
    }
  
    .items:hover {
      border-left: 2px solid ${props => props.theme.colors.gray};
    }
    
    .items {
        display: flex;
    }
    
    .items img {
        object-fit: contain;
        width: 14px;
    }
    
    .category {
      color: ${props => props.theme.colors.gray};
      font-family: ${props => props.theme.fonts.alternative};
      font-weight: 300;
      font-size: 14px;
      padding-left: 5%;
      letter-spacing: 0.5px;
      margin-bottom: 2%;
      margin-top: 10%;
    }
  
    .items {
        padding-left: 10%;
        color: rgb(205, 207, 194);
        margin-top: 0;
        margin-bottom: 1%;
        line-height: 46px;
        font-family: ${props => props.theme.fonts.alternative};
        max-height: 5vh;
    }
  
    img {
      margin-right: 5%;
    }
  
    .current-view {
      //color: rgba(200, 80, 80, 0.8);
      //color: rgb(175, 177, 164);
      color: white;
      background: rgb(115,107,94);
      border-left: 2px solid ${props => props.theme.colors.gray};
      background: linear-gradient(90deg, rgba(115,107,94,0.2) 0%, rgba(115,107,94,0) 100%);
    }
`