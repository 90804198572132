import {ApiStatusStyled} from "./apistatus.styled";
import React from "react";

const ApiStatus = (props) => {

    return (
    <ApiStatusStyled>
        <div className={'flex1'}>
            <h1>{props.server_data.name}</h1>
        </div>
        <div className={'info'}>
            <table>
                <thead>
                    <tr className={'title'}>
                        <td>Setting</td>
                        <td>Value</td>
                    </tr>
                </thead>
                <tbody>
                    <tr key={2137 + Math.random() * Math.random()} className={'info-element'}>
                        <td>Encryption</td>
                        <td>{props.server_data.response.encrypted ? 'True' : 'False'}</td>
                    </tr>
                    <tr key={2137 + Math.random() * Math.random()} className={'info-element'}>
                        <td>Execution</td>
                        <td>{props.server_data.response.execution ? 'True' : 'False'}</td>
                    </tr>
                    <tr key={2137 + Math.random() * Math.random()} className={'info-element'}>
                        <td>Self refresh</td>
                        <td>{props.server_data.response.self_refresh} seconds</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ApiStatusStyled>
)}

export default ApiStatus;