import styled from "styled-components";

export const ApiStatusStyled = styled.div`
  font-family: ${props => props.theme.fonts.technical};
  font-weight: 400;
  width: 25%;
  max-height: 25%;
  text-align: left;
  display: flex;
  flex-direction: column;
  background-color: rgba(15, 16, 15, 1);
  
  h1 {
    font-size: ${props => props.theme.fontSizes.medium};
    font-weight: 200;
    letter-spacing: 1px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1%;
  }

  .flex1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 1%;
  }

  .info {
    border: 1px solid ${props => props.theme.colors.gray};
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 1% auto 5%;
  }

  .info-element {
    border-bottom: solid ${props => props.theme.colors.gray} 1px;
    min-height: ${props => props.theme.fontSizes.small};
  }

  .red {
    color: ${props => props.theme.colors.reddim};
  }

  .info-element:last-child {
    border: 0;
  }

  .info-element p {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
  }

  strong {
    font-family: ${props => props.theme.fonts.alternative};
    font-size: 18px;
    color: white;
    font-weight: 400;
  }

  td > * {
    margin: 0;
    padding: 0;
  }

  td:nth-child(0) {
    text-align: center;
    border-right: 1px solid white;
  }

  tr {
    text-align: center;
  }

  .green {
    color: ${props => props.theme.colors.green};
  }

  table {
    border-collapse: collapse;
    border-color: #0f0f0f;
  }

  thead {
    text-align: center;
    border-bottom: 1px solid white;
  }

  border: 1px solid ${props => props.theme.colors.gray};
  box-sizing: border-box;
`