import {useEffect, useState} from "react";
import {LullabyViewStyled} from "./lullabyview.styled";
import authenticate from "../../../helpers/authenticate";
import centralServer from "../../../central.json";

const LullabyView = (props) => {
  const [lullabyPass, setLullabyPass] = useState('');

  useEffect(() => {
    props.setMarquee('MANAGEMENT / LULLABY')
  })

  const lullabyRequest = async () => {
    try {
      return await fetch(`${props.central_server.protocol}://${props.central_server.address}:${props.central_server.port}/nodes/lullaby`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "z_auth": props.authPass
        },
        body: JSON.stringify({"lullaby_pass": lullabyPass})
      })
    } catch(error) {
      console.log('[CENTRAL FETCH ERROR] '.red + error)
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    props.setVisibility(await lullabyRequest());
  }

  return (
    <LullabyViewStyled><div className={'container1'}><center>
      <center><div className={'authenticator'}>
        <form onSubmit={handleSubmit}>
          <p>LULLABY PASSWORD</p>
          <input name='accesskey' type={'password'} value={lullabyPass} onChange={(e => setLullabyPass(e.target.value))} maxLength={50}></input>
        </form>
      </div></center>
    </center></div></LullabyViewStyled>
  )
}

export default LullabyView;