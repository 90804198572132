import {ServiceStatusStyled} from "./servicestatus.styled";
import React from "react";

const ServiceStatus = (props) => {

    const sendKillRequest = (FormattedPid) => {
        let requestPath = '/nodes/execute';
        if (props.server_id === 0) {
            requestPath = '/execute'
        }


        fetch(`${props.central_server.protocol}://${props.central_server.address}:${props.central_server.port}${requestPath}`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "z_auth": props.authPass
            },
            body: JSON.stringify({
                "selector": `${props.server_id}`,
                "z_command": `sudo kill -SIGTERM ${FormattedPid}`
            })
        });
    }

    const sendServiceRequest = (serviceName, action) => {
        let requestPath = '/nodes/execute';
        if (serviceName) {
            props.setLog([...props.logger, {
                "server_name": props.server_name,
                "event": `SERVICE ${action.toUpperCase()}`,
                "content": serviceName
            }])

            if (props.server_id === 0) {
                requestPath = '/execute'
            }


            fetch(`${props.central_server.protocol}://${props.central_server.address}:${props.central_server.port}${requestPath}`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "z_auth": props.authPass
                },
                body: JSON.stringify({
                    "selector": `${props.server_id}`,
                    "z_command": `sudo service ${serviceName} ${action}`
                })
            });
        }
    }

    const killProcess = (pid) => {
        let processed = '';
        pid.forEach((element) => {
            processed += `${element} `
        })
        if (processed) {
            props.setLog([...props.logger, {
                "server_name": props.server_name,
                "event": "SIGTERM KILL",
                "content": processed
            }])
            sendKillRequest(processed)
        }
    }


    return (
     <ServiceStatusStyled>
         <div className={'body-cell'}><p>{props.server_data.name}</p></div>
         <div className={'body-cell'}>{props.server_data.pids[0] ? (<p className={'running'}>Running</p>) : (<p className={'not-running'}>Offline</p>)}</div>
         <div className={'body-cell process-killer'} onClick={() => killProcess(props.server_data.pids)}><p>Kill</p></div>
         <div className={'body-cell process-starter'} onClick={() => sendServiceRequest(props.server_data.name, 'start')}><p>Start</p></div>
         <div className={'body-cell service-killer'} onClick={() => sendServiceRequest(props.server_data.name, 'stop')}><p>Stop</p></div>
     </ServiceStatusStyled>
    )}

export default ServiceStatus;