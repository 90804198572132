import DefaultView from "../views/default-page/defaultview";
import ServerView from "../views/srv-view/srv-view";
import ServiceView from "../views/svc-view/svc-view";
import WidgetView from "../views/widget-view/widgetview";
import ExecuteView from "../views/execute-view/executeview";
import Apiview from "../views/api-view/apiview";
import Widgetview from "../views/widget-view/widgetview";
import LullabyView from "../views/lullabyview/lullabyview";

const Renderer = (props) => {

    const translateView = (param) => {
        switch (param) {
            case -1:
                return (
                  <DefaultView setMarquee={props.setMarquee} currentView={props.currentView}/>
                )
            case 1:
                return (
                  <ServerView setMarquee={props.setMarquee} central_server={props.central_server} availableNodes={props.availableNodes} authPass={props.authPass}/>
                )
            case 2:
                return (
                  <Apiview setMarquee={props.setMarquee} authPass={props.authPass} central_server={props.central_server} availableNodes={props.availableNodes} />
                )
            case 21:
                return (
                  <ServiceView availableNodes={props.availableNodes} central_server={props.central_server} setMarquee={props.setMarquee} authPass={props.authPass}/>
                )
            case 22:
                return (
                  <LullabyView setVisibility={props.setVisibility} central_server={props.central_server} setMarquee={props.setMarquee} authPass={props.authPass} />
                )
            case 23:
                return (
                  <ExecuteView central_server={props.central_server} setMarquee={props.setMarquee} availableNodes={props.availableNodes} authPass={props.authPass} />
                )
            case 101:
                return (
                  <Widgetview setMarquee={props.setMarquee} authPass={props.authPass} central_server={props.central_server} availableNodes={props.availableNodes} />
                )
            default:
                return (
                  <DefaultView setMarquee={props.setMarquee} currentView={props.currentView}/>
                )
        }
    }

    return (
      <>
          {translateView(props.currentView)}
      </>
    )
}

export default Renderer;