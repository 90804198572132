import { SidebarElementStyled } from "./sidebar-element.styled";

const SidebarElement = (props) => (
    <SidebarElementStyled>
        {props.element.pause ? <div className={"category"}>
            {`${props.element.name}`}
        </div> : <p className={`items ${props.element.id === props.currentView ? 'current-view' : ''}`} onClick={() => props.setView(props.element.id)}><img width={'14px'} src={props.element.icon} alt={''}></img>{props.element.name}</p>}
    </SidebarElementStyled>
)

export default SidebarElement;