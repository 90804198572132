import {ServerSummaryStyled} from "./serversummary.styled";
import React, {useEffect, useState} from "react";
import {LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, AreaChart, Area} from "recharts";

const ServerSummary = (props) => {

    const averageLoadHistory = [
        {name: '1', y: props.avgCPUHistory[0]},
        {name: '2', y: props.avgCPUHistory[1]},
        {name: '3', y: props.avgCPUHistory[2]},
        {name: '4', y: props.avgCPUHistory[3]},
        {name: '5', y: props.avgCPUHistory[4]},
        {name: '6', y: props.avgCPUHistory[5]},
        {name: '7', y: props.avgCPUHistory[6]},
        {name: '8', y: props.avgCPUHistory[7]},
        {name: '9', y: props.avgCPUHistory[8]},
        {name: '10', y: props.avgCPU},
    ]

    return (
    <ServerSummaryStyled>
        <div className={'stat-title'}><h1>Statistics summary</h1></div>
        <div className={'flex1'}>
            <h3>{props.avgCPU.toFixed(2)}% CPU USAGE</h3>
        </div>

        <div className={'chart'}>
            <LineChart width={505} height={100} margin={{right: 5}} data={averageLoadHistory}>
                <Line dot={1} isAnimationActive={false} type={'monotone'} strokeWidth={2} stroke={'rgb(37, 91, 145)'} dataKey="y"/>
                <Tooltip />
            </LineChart>
        </div>

        <p className={'update-info'}>{props.manual_fetch ? `Updated every ${props.refreshRate} seconds ` : `Synced with the central server`}</p>
        {/*<div className={'info'}>*/}
        {/*    <div className={'non-bar'}>*/}
        {/*        <p>AVG RAM USAGE {props.avgRAM.toFixed(3) * 1000}MB</p>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </ServerSummaryStyled>
    )
}

export default ServerSummary;