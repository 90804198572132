import styled from 'styled-components';

export const SelectServerStyled = styled.div`
    width: 100%;
    max-height: 100%;
    border-top: 0;

    .containerino {
        width: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column !important;
        padding-bottom: 2%;
        border-top: 0;
    }

    .disallowed {
        color: ${props => props.theme.colors.red};
    }

    h1 {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 1%;
        padding: 0;
        text-align: left;
        font-family: ${props => props.theme.fonts.technical};
        font-size: 1.5em;
        font-weight: 400;
    }

    .scrollable-selection {
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: thin;
    }

    .selected {
        background-color: rgba(50, 150, 50, 0.5) !important;
    }

    .selection-table {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: rgba(30, 31, 30, 1);
    }

    .selection-head-row {
        display: flex;
        background-color: rgba(50, 51, 50, .2);
        border-bottom: 1px solid gray;
        height: 5vh;
    }

    .selection-head-row .head-cell {
        padding-left: 0;
    }

    .selection-body-row .body-cell {
        height: 4vh;
        display: flex;
        justify-content: space-around;
    }

    .selection-body-row .body-cell p {
        margin-top: auto;
        margin-bottom: auto;
    }

    .selection-body-row {
        display: flex;
        flex-direction: row;
        background-color: rgba(30, 31, 30, 1);
    }

    .selection-body-row:nth-child(even) {
        background-color: rgba(50, 51, 50, 1);
    }

    .selection-head-row .head-cell:nth-child(2), .selection-body-row .body-cell:nth-child(2) {
        width: 80%;
    }

    .selection-head-row .head-cell:nth-child(1), .selection-body-row .body-cell:nth-child(1) {
        width: 20%;
    }
`