import styled from "styled-components";

export const ServiceStatusStyled = styled.div`
    font-family: ${props => props.theme.fonts.technical};
    font-weight: 400;
    width: 100%;
    text-align: center;
    flex-direction: row;
    background-color: rgba(30, 31, 30, 1);
    display: flex;
    border-radius: 5px;
    
    &:nth-child(even) {
        background-color: rgba(101, 101, 101, 0.1);
    }
    
    .red {
      color: ${props => props.theme.colors.reddim};
    }
  
    .green {
      color: ${props => props.theme.colors.green};
    }
    
    .body-cell {
        height: 4vh;
        margin: auto;
        display: flex;
        justify-content: space-around;
    }
    
    .body-cell p {
        margin: auto;
        font-weight: 400;
        font-family: ${props => props.theme.fonts.poppins};
        font-size: 16px;
    }

    .body-cell:nth-child(1) {
        width: 20%;
    }
    .body-cell:nth-child(2) {
        width: 10%;
        padding-left: 10%;
    }
    .body-cell:nth-child(3) {
        width: 10%;
        padding-left: 30%;
    }
    .body-cell:nth-child(4) {
        width: 10%;
    }
    .body-cell:nth-child(5) {
        width: 10%;
    }
    
    .process-killer:hover {
      color: ${props => props.theme.colors.reddim};
    }
  
    .process-starter:hover {
      color: green;
    }
  
    .service-killer:hover {
      color: ${props => props.theme.colors.reddim};
    }
  
    .running {
      color: ${props => props.theme.colors.green};
    }
  
    .not-running {
      color: ${props => props.theme.colors.reddim};
    }

    box-sizing: border-box;
`