import {ServerStatusStyled} from "./serverstatus.styled";
import React from "react";
import {ProgressBar} from "./progressbar.styled";

const ServerStatus = (props) => {

    const getMemPerecentage = (used_mem, total_mem) => (100 * used_mem) / total_mem;

    return (
      <ServerStatusStyled>
          <h1>Server list</h1>
          <table>
              <thead>
              <tr>
                  <td>ID</td>
                  <td>Name</td>
                  <td>Kernel</td>
                  <td>CPUs</td>
                  <td>TOTAL RAM</td>
              </tr>
              </thead>
              <tbody>
              {props.response.map((element) => {
                  let role_string = '';

                  if (element.roles) {
                      element.roles.forEach((element) => {
                          if (element.length) {
                              role_string = role_string + ' ' + `[${element}]`;
                          }
                      })
                  }
                  let encryption = 0;
                  if (element.id === 0) {
                      props.central_server.protocol === 'https' ? encryption++ : encryption = 0;
                  } else {
                      element.encrypted ? encryption++ : encryption = 0;
                  }
                  let displayImg = false;
                  switch (element.response.os_type) {
                      case 'ubuntu':
                          displayImg = true
                          break;
                      case 'mac':
                          displayImg = true
                          break;
                      case 'fedora':
                          displayImg = true
                          break;
                      case 'windows':
                          displayImg = true
                          break;
                  }

                  return (<>
                      <tr className={'server-line'} key={element.id}>
                          <td>{element.id}</td>
                          <td><div className={'encryption-icon white'}>{ element.encrypted ? (<img src={'/icons/Iconshield.svg'}></img>) : ''}<p>{element.name}</p></div></td>
                          <td>{element.response.kernel}</td>
                          <td>{element.response.cores}</td>
                          <td>{element.response.mem.toFixed(3) * 1000}MB</td>
                      </tr>
                      <tr className={'extended-row'}><td>{displayImg ? (<img width={'25px'} height={'25px'} src={`/icons/${element.response.os_type}.svg`} />): ('')}</td><td colSpan={3}><div className={'progress-parent'}><p className={'progress-desc'}>CPU USAGE: {element.response.load.toFixed(2)}%</p><ProgressBar className={'cpu-percent'} $percentage={element.response.load.toFixed(2)}>CPU</ProgressBar></div></td><td>{ element.encrypted ? (<div className={'encryption-icon'}><p>ENCRYPTED</p></div>) : (<div className={'encryption-icon red'}><img src={'/icons/Iconnoshield.svg'}></img><p>UNENCRYPTED</p></div>)}</td></tr>
                      <tr className={'extended-row'}><td></td><td colSpan={3}><div className={'progress-parent'}><p className={'progress-desc'}>MEMORY USAGE: {element.response.used_mem.toFixed(3) * 1000}MB</p><ProgressBar className={'mem-percent'} $percentage={getMemPerecentage(element.response.used_mem.toFixed(3) * 1000, element.response.mem.toFixed(3) * 1000)}>RAM</ProgressBar></div></td>{ element.response.execution ? <td></td> : (<td className={'encryption-icon'}><p className={'red'}>NO EXEC</p></td>)}</tr>

                      <tr className={'empty-row'}>
                          <td colSpan={5}>.</td></tr>
                  </>)
              })}
              </tbody>
          </table>
      </ServerStatusStyled>
    )}

export default ServerStatus;